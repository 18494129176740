<template>
  <div class="ele-body">
    <el-card shadow="never">

      <el-form
        :model="where"
        label-width="77px"
        class="ele-form-search"
        @keyup.enter.native="reload"
        @submit.native.prevent>
        <el-row :gutter="15">
          <el-col :md="6" :sm="12">
            <el-form-item label="时间:">
              <el-date-picker
                style="width: 90%;"
                v-model="where.created_at"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="12">
            <el-form-item label="等级:">
              <el-select v-model="where.level_id" clearable placeholder="请选择" filterable >
                <el-option
                  v-for="item in levelList"
                  :key="item.value"
                  :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12">
            <div class="ele-form-actions">
              <el-button
                type="primary"
                @click="reload"
                icon="el-icon-search"
                class="ele-btn-icon">查询
              </el-button>
              <el-button @click="reset">重置</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <ele-pro-table
        ref="table"
        :datasource="url"
        :where="where"
        :columns="columns"
        :response="response"
        :selection.sync="selection"
      >
        <!-- 表头工具栏 -->
        <!-- 操作列 -->
        <template slot="action" slot-scope="{ row }">
          <div v-if="row.is_end === 0">
            <el-link
              @click="openEdit(row)"
              icon="el-icon-edit"
              type="primary"
              :underline="false"
            >修改
            </el-link>
          </div>
          <div v-else>
            <el-link disabled>完成测试</el-link>
          </div>
        </template>
      </ele-pro-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <test-edit :visible.sync="showEdit" v-if="showEdit" :closeEdit="closeEdit" :testId="test_id" @done="reload"/>
  </div>
</template>

<script>
import testEdit from "./test-edit";

export default {
  name: "PushList",
  components: {testEdit},
  data() {
    return {
      // 表格数据接口
      url: "/Admin/pushList",
      response: {
        statusCode: 200,
        countName:'data.total',
        dataName: 'data.data'
      },
      // 表格列配置
      columns: [
        {
          columnKey: "selection",
          type: "selection",
          width: 45,
          align: "center",
          fixed: "left",
        },
        {
          columnKey: "index",
          type: "index",
          width: 45,
          align: "center",
          fixed: "left",
          showOverflowTooltip: true,
        },
        {
          prop: "user_name",
          label: "用户姓名",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "test_module",
          label: "前测模块",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "push_module",
          label: "推送模块",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "level_name",
          label: "测试等级",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "class_name",
          label: "年级",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "created_at",
          label: "创建时间",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          columnKey: "action",
          label: "操作",
          width: 230,
          align: "center",
          resizable: false,
          slot: "action",
        },
      ],
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 表格搜索条件
      where: {  },
      // 等级下拉数据
      levelList: [],
      test_id:0
    };
  },
  mounted() {
    this.queryLevelList()
  },
  methods: {
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({page: 1});
    },
    /* 显示编辑 */
    openEdit(row) {
      //this.current = row;
      this.test_id = row.id;
      this.showEdit = !this.showEdit;
    },
    closeEdit() {
        this.test_id = 0
        this.showEdit = !this.showEdit;
    },
    // 重置
    reset() {
      this.where = {};
      this.$nextTick(() => {
        this.reload();
      });
    },
    /* 查询等级列表 */
    queryLevelList() {
      this.$http.get('/Admin/level').then(res => {

        if (res.data.code === 200) {
          this.levelList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    }
  },
};
</script>

<style scoped>
</style>
