<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    title="修改推送"
    :visible="visible"
    width="50%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item
          v-for="(val, index) in ruleForm.domains"
          :key="val.key"
          label="模块名:"
        >
          <el-col :span="6">

            <el-input v-model="val.module_name" :disabled="true" placeholder=""></el-input>
          </el-col>

          <el-col :span="5">
            <el-form-item
              label="推题数量:"
            >
              <el-input v-model="val.push_num" :disabled="true" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="margin-left: 120px">
            <el-form-item
              :prop="'domains.' + index + '.is_push'"
              :rules="{
                required: true,
                message: '请至少选择一个 ',
                trigger: 'blur',
              }"
            >
              <el-radio-group v-model="val.is_push">
                <el-radio :label="1">推送</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form-item>

    </el-form>
    <div slot="footer">
      <el-button
        @click="resetForm">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    testId: 0,
    // 父组件方法，关闭弹框
    closeEdit: Function
  },
  data() {
    return {
      // 表单数据
      init: {},
      // 表单验证规则domains
      ruleForm:
        {
          domains: [
            {
              module_name: "",
              push_num: 0,
              is_push: 0,
              code: '',
              test_id: 0,
            },
          ],
        },
      // 等级下拉数据
      gradeSelection: [],

      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [],
      modules:[]

    }
  },
  mounted() {
   // this.queryRoles();  // 查询角色列表
    //this.moduleList()
  },
  created() {
    //如果有值就请求接口
    if(this.testId){
      this.getDetails(this.testId)
    }
  },
  watch: {
    data() {
      if (this.data) {
        //console.log(this.data)
        this.ruleForm = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.ruleForm = {};
        this.isUpdate = false;
      }
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          //console.log(this.ruleForm)
          this.loading = true;
          this.$http[ 'post']('/Admin/pushList', this.ruleForm).then(res => {
            //console.log(res)
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    // 关闭
    resetForm(ruleForm){
      // 调用父组件方法 隐藏弹框
      this.closeEdit()
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
    //获取详情
    getDetails(id) {
      this.$http.get('/Admin/pushList/'+id).then(res => {

        if (res.data.code === 200) {
          this.ruleForm.domains = res.data.data;
          console.log(this.ruleForm.domains)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    }
  }
}
</script>

<style scoped>
</style>
